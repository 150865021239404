import "./_style.scss";
import data from "../data/data.json";
import { useEffect, useRef, useState } from "react";
import svgPlay from "./play.svg";
import svgPause from "./pause.svg";
import song from "./song.mp3";
import { Howl } from "howler";

interface TextItem {
  text: string;
  font: string;
  size: string;
  id: number;
  opacity: number;
}

const fixedPositionsPercent = [
  { top: "10%", left: "10%" },
  { top: "23%", left: "10%" },
  { top: "30%", left: "60%" },
  { top: "40%", left: "5%" },
  { top: "70%", left: "15%" },
  { top: "15%", left: "55%" },
  { top: "60%", left: "65%" },
  { top: "80%", left: "47%" },
  { top: "85%", left: "5%" },
  { top: "2%", left: "5%" },
  { top: "48%", left: "75%" },
  { top: "59%", left: "10%" },
  { top: "15%", left: "1%" },
  { top: "4%", left: "60%" },
  { top: "38%", left: "67%" },
  { top: "6%", left: "33%" },
  { top: "70%", left: "65%" },
  { top: "22%", left: "47%" },
  { top: "31%", left: "31%" },
  { top: "10%", left: "5%" },
];

export default function Valentine() {
  const [enlargedText, setEnlargedText] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [sound, setSound] = useState<Howl | null>(null);
  useEffect(() => {
    const newSound = new Howl({
      src: [song],
      html5: true, // Enable HTML5 audio for better autoplay support
    });
    setSound(newSound);

    // Add class to trigger initial fade-in animation after render
    setTimeout(() => {
      document.querySelectorAll(".text, .centered").forEach((el) => {
        el.classList.add("fade-in");
      });
    }, 2000);

    setTimeout(() => {
      document.querySelectorAll(".custom_btn").forEach((el) => {
        el.classList.add("fade-in_button");
      });
    }, 2000);

    // Add class to trigger final opacity animation after initial fade-in completes
    setTimeout(() => {
      document.querySelectorAll(".text, .centered").forEach((el) => {
        el.classList.add("final-animation");
      });
    }, 5000); // 2 seconds for fade-in + 100ms delay
  }, []);

  const handlePlayPause = () => {
    if (sound) {
      if (isPlaying) {
        sound.pause();
      } else {
        sound.play();
      }
      setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    }
  };

  const handleTextClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.currentTarget as HTMLDivElement;
    target.style.opacity = "0";
  };

  return (
    <div className="valentine_container container">
      <div className="custom_btn" onClick={handlePlayPause}>
        {isPlaying ? (
          <img src={svgPause} alt="Pause" />
        ) : (
          <img src={svgPlay} alt="Play" />
        )}
      </div>

      <div
        style={{ position: "relative" }}
        className="wrap"
        onTouchStart={() => setEnlargedText("")} // При торканні будь-куди на екрані скидаємо збільшення тексту
      >
        {data.map((item, index) => {
          const isEnlarged = enlargedText === item.text;

          if (index === 0) {
            return (
              <p
                key={index}
                className={`text centered ${isEnlarged ? "enlarged" : ""}`}
                style={{
                  fontFamily: item.font,
                  opacity: item.opacity,
                  fontSize: item.size,
                }}
              >
                {item.text}
              </p>
            );
          } else {
            const { top, left } = fixedPositionsPercent[index];
            return (
              <div
                onClick={handleTextClick}
                className="text_wrap"
                style={{
                  position: "absolute",
                  top: top,
                  left: left,
                  width: "auto",
                  height: "auto",
                  opacity: 1,
                  transition: `opacity 1s ease-in-out`,
                }}
              >
                <p
                  key={index}
                  className={`text ${isEnlarged ? "enlarged" : ""}`}
                  style={{
                    // position: "absolute",
                    // top: top,
                    // left: left,
                    fontFamily: item.font,
                    opacity: 0,
                    fontSize: item.size,
                    transition: `opacity 2s ease-in-out`, // Smooth transition to final opacity
                  }}
                >
                  {item.text}
                </p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

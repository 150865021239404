import React from "react";
import Valentine from "./components/pages/page-valentine";
import "./scss/app.scss";

function App() {
  return (
    <div>
      <Valentine />
    </div>
  );
}

export default App;
